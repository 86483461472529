<template>
  <Dialog
    :header="
      editActionIndex == null
        ? $t('settings.createAction')
        : $t('settings.editAction')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '70vw' }"
  >
    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="role">{{ $t("settings.role") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3 p-fluid mb-2">
        <InputText
          id="role"
          :class="{
            'p-invalid': v$.actionObject.role.$invalid && submitted,
          }"
          aria-describedby="country-error"
          v-model="v$.actionObject.role.$model"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.actionObject.role.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.actionObject.role.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.role")) }}
        </li>
      </ul>
    </div>

    <ActionContent
      v-for="(content, index) in actionObject.content"
      :key="index"
      :contentValue="content"
      :submittedValue="submitted"
      :indexValue="index"
      @deleteActionContent="deleteActionContent"
    ></ActionContent>
    <div class="flex justify-content-end my-1">
      <Button :label="$t('settings.addContent')" @click="addContent"></Button>
    </div>
    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="
          editActionIndex == null ? $t('global.create') : $t('global.save')
        "
        class="p-button-success"
        @click="create"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close()"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
import ActionContent from "./ActionContent.vue";
export default {
  name: "Add edit action",
  components: {
    ActionContent,
  },
  props: ["display", "editActionIndex"],
  emits: ["closeAddEditAction"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      submitted: false,
      loadingButton: false,
      actionObject: {
        role: "",
        content: [
          {
            action: "",
            message: "",
            notification: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["actionsObject"]),
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      // console.log(this.caseValue);
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
        this.submitted = false;
        this.v$.$reset();
      } else {
        if (this.editActionIndex != null) {
          this.actionObject = JSON.parse(
            JSON.stringify(this.actionsObject[this.editActionIndex])
          );
        }
      }
    },
  },
  validations: {
    actionObject: {
      role: {
        required,
      },
      content: {
        required,
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("roles");
  },
  methods: {
    clearAction() {
      this.actionObject = {
        role: "",
        content: [
          {
            action: "",
            message: "",
            notification: "",
          },
        ],
      };
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      console.log(this.actionObject);
      if (result) {
        const tempActions = [...this.actionsObject];
        if (this.editActionIndex != null) {
          tempActions[this.editActionIndex] = this.actionObject;
          this.$store.commit("actionsObject", tempActions);
          this.close();
        } else {
          this.close(this.actionObject);
        }
      }
    },
    close(newAction) {
      this.$emit("closeAddEditAction", newAction);
      this.clearAction();
    },
    addContent() {
      this.actionObject.content.push({
        action: "",
        message: "",
        notification: "",
      });
    },
    deleteActionContent(index) {
      if (this.actionObject.content.length > 1) {
        this.actionObject.content.splice(index, 1);
      } else {
        this.$confirm.require({
          message: this.$t("settings.oneAction"),
          header: this.$t("global.warning"),
          icon: "pi pi-info-circle",
          acceptLabel: this.$t("global.ok"),
          rejectClass: "hidden",
        });
      }
    },
  },
};
</script>
