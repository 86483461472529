<template>
  <div
    class="grid grid-nogutter border-1 my-3 p-3"
    style="border-color: #cccccc"
  >
    <div class="col-12 lg:col-5">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="action">{{ $t("settings.action") }}*:</label>
        </div>
        <div class="field col-11 lg:col-8 pt-0 md:pt-3 p-fluid mb-2">
          <InputText
            id="action"
            v-model="v$.content.action.$model"
            :class="{
              'p-invalid': v$.content.action.$invalid && submitted,
            }"
          >
          </InputText>
        </div>
        <div class="col-1 text-center" v-if="screenWidth < 1021">
          <Button
            icon="mdi mdi-close-circle mdi-18px"
            class="p-button-rounded p-button-secondary p-button-text"
            @click="deleteActionContent(indexValue)"
          />
        </div>
        <ul
          v-if="v$.content.action.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.content.action.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("settings.action")) }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-11 lg:col-6">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-3 pb-0">
          <label for="notification">{{ $t("settings.notification") }}*:</label>
        </div>
        <div class="field col-12 lg:col-9 pt-0 md:pt-3 p-fluid mb-2">
          <Dropdown
            id="notification"
            v-model="content.notification"
            :options="notificationForActions"
          >
          </Dropdown>
        </div>
        <!-- <ul
          v-if="v$.content.notification.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li
            v-for="(error, index) of v$.content.notification.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("settings.notification")) }}
          </li>
        </ul> -->
      </div>
    </div>
    <div class="col-1 text-center align-self-center" v-if="screenWidth > 1021">
      <Button
        icon="mdi mdi-close-circle mdi-18px"
        class="p-button-rounded p-button-secondary p-button-text"
        @click="deleteActionContent(indexValue)"
      />
    </div>
    <div class="col-12">
      <div class="grid grid-nogutter vertical-container align-items-center">
        <div class="col-12 lg:col-1 pb-0">
          <label for="message">{{ $t("settings.message") }}*:</label>
        </div>
        <div class="field col-12 lg:col-9 pt-0 md:pt-3 p-fluid mb-2 ml-3">
          <Textarea
            id="message"
            v-model="v$.content.message.$model"
            :class="{
              'p-invalid': v$.content.message.$invalid && submitted,
            }"
            rows="6"
            cols="30"
          />
        </div>
        <ul
          v-if="v$.content.message.$error && submitted"
          class="p-error text-xs col-12 lg:col-9 col-offset-0 lg:col-offset-3 pt-0 pl-3 mt-0"
        >
          <li v-for="(error, index) of v$.content.message.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("settings.message")) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
export default {
  name: "Action content",
  setup: () => ({ v$: useVuelidate() }),
  props: ["contentValue", "submittedValue", "indexValue"],
  data() {
    return {
      severityLevels: [1, 2, 3, 4],
    };
  },
  validations: {
    content: {
      action: {
        required,
      },
      message: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["roles", "screenWidth", "notificationForActions"]),
    content() {
      return this.contentValue;
    },
    submitted() {
      return this.submittedValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  methods: {
    deleteActionContent(index) {
      this.$emit("deleteActionContent", index);
    },
  },
};
</script>
